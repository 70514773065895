const modalState = reactive({
  isOpen: false,
  website: '',
})

const showModal = (website: string = '') => Object.assign(modalState, { isOpen: true, website })
const hideModal = () => modalState.isOpen = false

export {
  hideModal,
  showModal,
  modalState,
}
